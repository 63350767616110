import axios from "axios";
import AppConfig from "../config";
import { UserType } from "./ActionType";

const { API_URL } = AppConfig;

export const getUserByToken = (
  token = localStorage.getItem("PHARMA_SOS_USER")
) =>
  function (dispatch) {
    return axios
      .get(`${API_URL}/users/byToken`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.data.success) {
          dispatch({ type: UserType.GET_USER, payload: res.data.result });

          return res.data.result;
        }

        return undefined;
      })
      .catch(() => {
        localStorage.removeItem("PHARMA_SOS_USER");
      });
  };
