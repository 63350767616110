import AssetPath from "../../AssetPath/AssetPath";

const partners = [
  {
    Title: "Document IT",
    image: AssetPath.partners.documentIt,
    linkedin: "https://www.linkedin.com/company/document-it-llc/",
    website: "https://www.documentit.com/",
  },
  {
    Title: "ZS",
    image: AssetPath.partners.zs,
    linkedin: "https://www.linkedin.com/company/zs-associates/",
    website: "https://www.zs.com/",
  },
  {
    Title: "Chryselys",
    image: AssetPath.partners.chryselys,
    linkedin: "https://www.linkedin.com/company/chryselys/",
    website: "https://chryselys.com/",
  },
];
export default function OurPartners({ className }) {
  return (
    <section className={`our-partners spacing-top ${className}`}>
      <div className="container">
        <h4 className="text-center mb-2 h3 text-primary" data-aos="fade-up">
          Our Partners
        </h4>
        <div className="d-flex flex-column flex-md-row flex-lg-row align-items-center justify-content-center">
          {partners.map((data, i) => (
            <div className="image" data-aos="fade-up">
              <a href={data.website} target="_blank" rel="noreferrer">
                <img src={data.image} alt={data.Title} title={data.Title} />
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
