import { Linkedin } from "feather-icons-react";

const FooterLinks = [
  {
    links: [
      // {
      //   title: "Home",
      //   link: "/",
      // },
      // {
      //   title: "Agenda",
      //   link: "/agenda",
      // },
      // {
      //   title: "Sponsors",
      //   link: "/sponsors",
      // },
      {
        title: "About Us",
        link: "/about-us",
      },
      {
        title: "Events and Registration",
        link: "/events-and-registration",
      },
      {
        title: "FAQ",
        link: "/faq",
      },
      {
        title: "Terms and Conditions",
        link: "/terms-and-conditions",
      },
      {
        title: "Privacy Policy",
        link: "/privacy-policy",
      },
    ],
    socialMedia: [
      // {
      //   title: "facebook",
      //   icon: <Facebook />,
      //   url: "",
      // },
      {
        title: "LinkedIn",
        icon: <Linkedin />,
        url: "https://www.linkedin.com/company/pharma-sos/",
      },
      // {
      //   title: "Twitter",
      //   icon: <Twitter />,
      //   url: "",
      // },
    ],
  },
];

export default FooterLinks;
