import { useState } from "react";
import { Col, Row } from "antd";
import AssetPath from "../../AssetPath/AssetPath";
import ModalBox from "../../Components/ModalBox/ModalBox";

export default function Speakers() {
  const [open, setOpen] = useState(false);
  const [showData, setShowData] = useState("");

  const handleShowData = (items) => {
    setShowData(items);
    setOpen(true);
  };

  const SpeakersData = [
    {
      name: "Ankit Gupta",
      position: "Director, Data Science",
      companyName: "Novartis",
      profileImage: AssetPath.speakers.ankit,
    },
    {
      name: "Bala Arunachalam",
      position: "Associate Director - Commercial Insights and Analytics",
      companyName: "Novo Nordisk US",
      profileImage: AssetPath.speakers.balaArunachalam,
      desc: (
        <>
          <p>
            Bala Arunachalam is a seasoned Insights and Analytics (I&A)
            professional with over 15 years of experience in the US pharma,
            having worked with multiple Fortune 500 organizations. He currently
            serves as Associate Director of Insights and Analytics at Novo
            Nordisk US. Bala specializes in data analysis, synthesis,
            integration, and reporting, with a focus on AI/ML applications. His
            commercial expertise spans a range of therapeutic categories,
            including Diabetes, Obesity, CNS, Oncology, and Hemophilia. Bala’s
            passion is to bring innovative analytics solutions to business
            problems, and he excels in guiding teams through challenging
            projects within matrixed organizations. Bala has received numerous
            accolades, including Novo Commercial I&A’s Internal Glory Award for
            fostering innovation and PMSA’s Best Poster Award for a revised NPP
            targeting strategy using ML.
          </p>
          <p>
            Prior to Novo Nordisk, Bala held roles at Bristol Myers Squibb and
            Merck & Co., with I&A projects that significantly impacted strategic
            decisions. Bala brings a background in software engineering with an
            MS in Electrical Engineering from Michigan Technological University
            and an MBA from the University of Michigan.
          </p>
        </>
      ),
    },
    {
      name: "Balasubramanian Sankaranarayanan",
      companyName: "Thryve Digital",
      profileImage: AssetPath.speakers.balasubramanianSankaranarayanan,
      desc: (
        <>
          <p>
            Balasubramanian Sankaranarayanan is the co-founder at Thryve and has
            been driving the reinvention and transformation of Healthcare
            delivery through innovative Business and Technology solutions. He
            has held several executive positions during his professional career
            globally, including at Atos SE, GE, and Cognizant. Prior to joining
            Thryve, he was an SVP at Atos SE where he led their Consulting &
            System Integration business.
          </p>

          <p>
            He is a recognized thought leader on Digital Health and has been a
            keynote speaker at conferences and industry forums within India and
            abroad. A certified consultant on USFDA Regulatory Affairs, he has
            also held multiple board-level affiliations with industry bodies
            such as the Drug Information Association, ISCR, and others.{" "}
          </p>

          <p>
            He is an alumnus of the University of Madras (Bachelors in Chemistry
            from Loyola College), Manipal Academy of Higher Education (Masters
            in Business Administration) and IIM Bangalore (Executive General
            Management).
          </p>
        </>
      ),
    },
    {
      name: "Chetan Kulkarni",
      companyName: "Chryselys",
      profileImage: AssetPath.speakers.chetanKulkarni,
      desc: (
        <>
          <p>
            Chetan is a seasoned pharmaceutical professional with over a decade
            of experience in business insights, analytics, data science, and
            early commercial planning. He has a strong track record of
            delivering impactful insights, particularly in oncology, rare
            diseases and cell and gene therapies.{" "}
          </p>

          <p>
            As Executive Director at Chryselys, Chetan specializes in leveraging
            generative AI and advanced analytics to address client challenges.{" "}
          </p>

          <p>
            Prior to Chryselys, Chetan spent majority of his career in Novartis
            and Kite Pharma, A Gilead company, leading integrated insights and
            analytics teams. He built and led several teams in areas of brand
            analytics, forecasting and market research.
          </p>
        </>
      ),
    },
    {
      name: "Daniel Rose",
      position: "VP, Insulet Global Business Services",
      companyName: "Insulet Corporation",
      profileImage: AssetPath.speakers.danielRose,
      desc: (
        <>
          <p>
            Over Dan’s extensive career, his time has been split largely between
            Chief Information Officer (CIO) roles and heads of Global Business
            Services (GBS) assignments worldwide.
          </p>

          <p>
            During his career, Dan was often at the center of challenging
            business transformation assignments, where he learned to deeply
            appreciate the value and impact of focusing on the human side of
            systemic change as the most crucial aspect of nearly all
            transformation efforts. Dan’s first real appreciation of this
            reality was as a younger man serving in the United States Air Force.
          </p>

          <p>
            Between Dan’s formative years in the military and today, he has had
            assignments spanning numerous industries, including defense, health
            care, retail, pharmaceutical, automotive, manufacturing, MedTech,
            and agriculture, for companies like Zeneca, Syngenta, Novartis, and
            now Insulet.
          </p>
        </>
      ),
    },
    {
      name: "Deviprasad Singh",
      position: "Managing Director (India) and GDH",
      companyName: "Chryselys",
      profileImage: AssetPath.team.deviPrasadSingh,
      desc: (
        <>
          <p>
            Deviprasad is Managing Director – India & Global Delivery Head at
            Chryselys, and leads India Operations in all aspects including
            people, process, and technology. He delivers direction to the
            business so we are steadily on track towards achieving our vision.
            He is a strategic leader with over three decades of experience at
            major pharma organizations like Novartis, Eli Lilly and Co., and
            Bristol Myers Squibb.
          </p>
          <p>
            He has extensive and diverse experience in data operations, insights
            & analytics, sales, and marketing, with a nuanced understanding of
            commercial business needs. He has successfully led large teams
            locally and globally across many therapeutic areas, in highly
            matrixed multinational companies.
          </p>
          <p>
            Deviprasad earned his Post Graduation in Business Management and a
            Bachelor of Science in Chemistry from Mumbai University.
          </p>
        </>
      ),
    },
    {
      name: "Harpreet Singh",
      position: "Co-founder",
      companyName: "ProcDNA",
      profileImage: AssetPath.speakers.harpreetSingh,
    },
    {
      name: "Jisa Baji",
      position: " Global Head of Insights and Analytics",
      companyName: "Sandoz",
      profileImage: AssetPath.speakers.jisaBaji,
    },
    {
      name: "Karthik Chidambaram",
      position: "Head of Data Asset Management",
      companyName: "Roche & President Pharma-SOS",
      profileImage: AssetPath.team.KarthikChidambaram,
    },
    {
      name: "Latha Srinivasan",
      position: "Author",
      profileImage: AssetPath.speakers.lathaSrinivasan,
      desc: (
        <>
          <p>
            Latha Srinivasan, an award-winning Author from Chennai, India,
            brings over 17 years of experience across leading media houses like
            The Times of India, Yahoo! India, Deccan Chronicle, DNA, and NewsX.
            Specializing in the Indian entertainment sector and Tamil Nadu
            politics, Latha is known as one of South India's leading
            entertainment Author.
          </p>
          <p>
            A breast cancer survivor, she actively promotes awareness and has
            authored a self-help book, The Pink Elephant, to inspire and guide
            others through their journey with cancer.
          </p>
        </>
      ),
    },

    {
      name: "Madhu Krishnan",
      position: "Head Data Science & BI solutions",
      companyName: "BI&A, BMS",
      profileImage: AssetPath.speakers.madhuKrishnan,
    },
    {
      name: "Mohit Sood",
      position: "Regional Managing Principal",
      companyName: "ZS India",
      profileImage: AssetPath.speakers.mohitSood,
      desc: (
        <>
          <p>
            As the India Regional Managing Principal at ZS, Mohit Sood
            accelerates innovation and delivery excellence. He has over 15 years
            of life sciences consulting experience, with expertise in
            patient-centric analytics, AI-driven solutions for clinical trial
            optimization, and digital marketing.
          </p>
          <p>
            Mohit has also spearheaded ZS’s New Delhi office expansion and its
            R&D excellence practice, developing AI-driven solutions for clinical
            trial management. Passionate about integrating analytics and
            technology, his work centers on transforming how businesses consume
            data for better decision-making.
          </p>
        </>
      ),
    },

    {
      name: "Prakash Karaka",
      position: "Chief AI Officer",
      companyName: "Chryselys",
      profileImage: AssetPath.speakers.prakashKaraka,
      desc: (
        <>
          <p>
            As Chief AI Officer, Prakash is a guide and a collaborator with
            ethics and governance in mind. His focus is on integrating AI and
            Generative AI technologies, steering Chryselys’ vision towards
            harnessing vast datasets and yielding novel insights & solutions.
            This involves leveraging AI’s predictive capabilities and GenAI’s
            creative potential to revolutionize solutions to address client
            needs.
          </p>
          <p>
            With a deep commitment to leveraging technology for healthcare
            advancement, Prakash brings over 17 years of experience in
            engineering, AI, data analytics, and machine learning to the
            pharmaceutical management science field.
          </p>
          <p>
            Prakash is at the forefront of developing AI solutions that are
            secure, personalized, and transformative for the Pharma industry.
            His roles at Amazon, Microsoft, and Intel have been marked by
            significant contributions in areas ranging from cloud-based big data
            analytics to AI-driven retail and developer services.
          </p>
          <p>
            Prakash earned a Bachelor of Engineering in Electronics and
            Communications from Andhra University, India.
          </p>
        </>
      ),
    },
    {
      name: "Raghavendran Jayasubramanian",
      position: "Group Head - Data Science",
      companyName: "Novartis",
      profileImage: AssetPath.speakers.raghavendranJayasubramanian,
    },
    {
      name: "Raghuveeran Sowmyanarayanan",
      position: "Global AI Delivery Head",
      companyName: "Wipro Technologies",
      profileImage: AssetPath.speakers.raghuveeranSomwyanarayan,
    },
    {
      name: "Rajan Dua",
      position: "Senior Engagement Lead",
      companyName: "ProcDNA",
      profileImage: AssetPath.speakers.placeholderMenImage,
    },

    {
      name: "Somsekhar Pal",
      position: "Principal",
      companyName: "ZS India",
      profileImage: AssetPath.speakers.somsekharPal,
    },

    {
      name: "Sakthi Annamalai",
      position: "Head Data Management and Strategy",
      companyName: "Roche & India Conference Chair, Pharma-SOS",
      profileImage: AssetPath.team.sakthiAnnamalai,
    },
    {
      name: "Siva Kumar Padmanabhan",
      position: "MD & Head of Global Innovation & Technology Center",
      companyName: "AstraZeneca India",
      profileImage: AssetPath.speakers.sivaKumarPadmanabhan,
      desc: (
        <>
          <p>
            Siva Kumar Padmanabhan is the Managing Director and Head of the
            India Global Innovation and Technology Centre at AstraZeneca India
            Private Limited (AZIPL).
          </p>

          <p>
            He is also responsible globally for all Enterprise Platforms and
            Solutions provided by AstraZeneca IT to the business. Since joining
            AstraZeneca in 2014, Siva has been playing a key role in the
            transformation of AZ-IT - right from insourcing IT services,
            building a strong leadership team, developing leading-edge data and
            digital capabilities and being a key enabler in expanding AZIPL's
            Indian footprint and innovation capability. Siva is also a member of
            the NASSCOM GCC Council 2023-25.
          </p>

          <p>
            Previously, Siva led the Global In-house Centre for IT & Digital at
            Flex, a Fortune 500 company, with global accountability for their
            customer-facing digital and supply chain solutions. His earlier part
            of the career was in management and technology consulting with PwC,
            in India and the US. Siva has an MBA in Finance and Information
            Systems from the Indian Institute of Management (IIM), Bangalore.
          </p>
        </>
      ),
    },
    {
      name: "Siddharth Mandal",
      position: "Senior Manager Data Science",
      companyName: "Roche",
      profileImage: AssetPath.speakers.siddharthMandal,
      desc: (
        <>
          <p>
            Siddharth spearheads a diverse team of data scientists and engineers
            who are at the forefront of creating innovative solutions that
            address complex healthcare challenges. With over a decade of
            experience in developing and deploying cutting-edge AI solutions, he
            has been pioneering innovations in Generative AI, Recommendation
            Engines, Predictive Modeling, Deep Learning, and MLOps.
          </p>
          <p>
            He enjoys exploring the ethical implications of AI and advocating
            for responsible AI practices.
          </p>
        </>
      ),
    },
    // {
    //   name: "Shantanu Bose",
    //   position: "Principal Data Science",
    //   companyName: "Axtria",
    //   profileImage: AssetPath.speakers.shantanuBose,
    //   desc: (
    //     <>
    //       <p>
    //         Shantanu is a data science leader shaping the digital landscape of
    //         the organization by identifying greenfield opportunities and
    //         building the AI/ product strategy around that. He is highly skilled
    //         in all the phases of data-first digital transformation – from
    //         hands-on development of PoC, demonstrating value, to a
    //         self-sustained product structure. As a fluent communicator, he has
    //         led the design of multiple hackathons to evangelize data science in
    //         highest levels of the organization (CXOs) and cross-functional BU
    //         teams. He is proficient in end-to-end product development & delivery
    //         in a volatile environment.
    //       </p>
    //       <p>He was recognized as a Top 10 data scientist in 2017.</p>
    //     </>
    //   ),
    // },
    {
      name: "Steven Sbar",
      position: "VP Customer Engagement & Enterprise Analytics",
      companyName: "Novartis US",
      profileImage: AssetPath.speakers.stevenSbar,
    },
    {
      name: "Sudharsan Mohan",
      position: "Associate Director-Products",
      companyName: "Chryselys",
      profileImage: AssetPath.speakers.sudharsanMohan,
      desc: (
        <>
          <p>
            With over 8 years of experience in data science and data
            engineering, Sudharsan Mohan is highly skilled in transforming data
            into actionable insights. His expertise covers data engineering,
            analytics, predictive modeling, business intelligence, product
            development, and Generative AI.
          </p>

          <p>
            Currently leading product development at Chryselys, he focuses on
            driving innovation and strategic advancements through data.
          </p>

          <p>
            Sudharsan has a strong track record of delivering data-driven
            solutions that enhance operational efficiency and accuracy, crafting
            advanced regression and classification models, and solving complex
            business challenges with predictive analytics and data mining.
          </p>
        </>
      ),
    },
    {
      name: "Suzanne Marzziotti",
      position: "Head, Business Solutions",
      companyName: "Chryselys",
      profileImage: AssetPath.speakers.suzanneMarzziotti,
      desc: (
        <>
          <p>
            With over 30 years of enriching the pharmaceutical and CPG
            industries with actionable insights, Suzanne Marziotti spearheads
            the business solutions vertical at Chryselys. In her role as Head of
            Business Solutions, she strategically applies data to catalyze
            growth and customize solutions that align with the complex needs of
            the pharma industry.
          </p>

          <p>
            Suzanne’s professional journey is marked by a strong emphasis on
            precision medicine reporting and commercial analytics, fostering the
            use of generative AI to enhance predictive modelling across
            therapeutic domains.
          </p>
          <p>
            Her approach weaves together cutting-edge technology and in-depth
            industry knowledge, resulting in robust strategies that elevate both
            client experience and patient care.
          </p>
          <p>
            Suzanne holds a BS in Microbial Genetics from Cornell University,
            complemented by an MBA from NYU’s Stern School of Business, which
            has empowered her to merge scientific inquiry with strategic
            implementation.
          </p>
        </>
      ),
    },
    {
      name: "Venkat Vishwanathan",
      position: "Chief People Officer",
      companyName: "Roche India",
      profileImage: AssetPath.speakers.placeholderMenImage,
    },
    {
      name: "Vignesh Krishnakumar",
      position: "Senior Manager",
      companyName: "GATE Roche",
      profileImage: AssetPath.speakers.vigneshKrishnakumar,
    },

    {
      name: "Vikas Gade",
      position: "Lead Business Analytics",
      companyName: "Chryselys",
      profileImage: AssetPath.speakers.vikasGade,
      desc: (
        <>
          <p>
            Vikas is a strategic business consultant with over 8 years of
            expertise in Oncology, specializing in business insights, analytics,
            and advanced decision-making frameworks.
          </p>
          <p>
            As an Associate Director at Chryselys, he leads key initiatives
            across HCP analytics, patient analytics, and field force operations,
            driving impactful solutions that enhance both clinical and
            commercial outcomes.
          </p>
          <p>
            Prior to Chryselys, Vikas built a strong foundation at Novartis and
            ZS, where he focused on patient analytics, segmentation & targeting,
            and advanced analytics. His deep experience in these areas enables
            him to deliver data-driven strategies that consistently maximize
            business potential in the competitive pharmaceutical landscape.
          </p>
        </>
      ),
    },
  ];

  return (
    <>
      <section
        className="speakers our-team spacing position-relative "
        data-aos="fade-up"
      >
        <div className="container">
          <div className="d-flex flex-column flex-md-row flex-lg-row align-items-center justify-content-between">
            <div className="text-center w-100">
              <h4 className="title mb-3 text-primary text-center">
                Meet the Speakers
              </h4>
            </div>
          </div>
          <div className="team-list">
            {/* <Carousel {...carouselConfig} ref={carouselRef}> */}
            <Row
              gutter={[
                { xs: 0, sm: 3, md: 3, lg: 3 },
                { xs: 0, sm: 0, md: 0, lg: 0 },
              ]}
            >
              {SpeakersData.map((members, i) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 6 }}
                  key={i}
                >
                  <div
                    className="team-details position-relative cursor-pointer"
                    onClick={() => handleShowData(members)}
                  >
                    <div className="profile-image rounded overflow-hidden">
                      <img
                        src={members.profileImage}
                        alt={members.name}
                        className="w-100 rounded"
                      />
                    </div>
                    <div className="details text-center card">
                      <h4 className="font-4 font-weight-bold mb-1">
                        {members.name}
                      </h4>
                      <p className="text-ellipsis2 mb-0">
                        {members.companyName || members.position}
                      </p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            {/* </Carousel> */}
          </div>
        </div>
      </section>

      <ModalBox
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={null}
        width={900}
      >
        <div className="speaker-profile-informations">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 9 }}
              lg={{ span: 9 }}
            >
              <div className="profile-image rounded overflow-hidden">
                <img
                  src={showData.profileImage}
                  alt={showData.name}
                  className="w-100 rounded"
                />
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 15 }}
              lg={{ span: 15 }}
            >
              <div className="details text-left card p-0">
                <h4 className="text-primary mb-1 border-bottom pb-2 mb-3">
                  <span className=" font-weight-bold">{showData.name}</span>
                  <span className="font-2 fon-weight-normal text-primary d-block">
                    {showData.position}
                    {showData.position && showData.companyName ? ", " : ""}
                    <strong>{showData.companyName}</strong>
                  </span>
                </h4>

                <div className="profile-desc">{showData.desc}</div>
              </div>
            </Col>
          </Row>
        </div>
      </ModalBox>
    </>
  );
}
