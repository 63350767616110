import { Col, Row } from "antd";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";
import AssetPath from "../../AssetPath/AssetPath";
import { useHistory } from "react-router-dom";

export default function AboutSection({ activeButton }) {
  const navigate = useHistory();

  return (
    <section className="who-we-are spacing bg-white">
      <div className="container">
        <Row
          gutter={[
            { xs: 10, sm: 16, md: 24, lg: 24 },
            { xs: 10, sm: 16, md: 24, lg: 24 },
          ]}
          justify="center"
          align="middle"
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            className="text-center"
          >
            <img
              src={AssetPath.images.aboutUs}
              alt="Pharma-SOS - Commercial Operations Community"
              data-aos="fade-up"
            />
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            data-aos="fade-up"
          >
            {/* <h3 className="sub-title text-uppercase font-2 text-primary">
              About Us
            </h3> */}
            <h4 className="title mb-3 text-primary">Who We Are?</h4>
            <p className="text-gray">
              Pharma-SOS is a non-profit organization, with a mission to build a
              community of professionals working in the pharmaceutical /
              biotechnology operations arena. 
            </p>
            <p className="text-gray">
              With the ever-changing need for data driving decision making and
              the push for efficiencies in the industry, there is a lot of
              hustle and bustle for the professionals bringing all the
              strategies and aspirations to life, with the right operations,
              processes and systems. 
            </p>
            <p className="text-gray">
              Pharma-SOS provides a platform for these professionals to network
              with each other, learning best practices and success stories from
              each other. In addition, the Pharma-SOS will also provide a
              platform for the community to advocate and influence for the right
              initiatives in the broader marketplace.
            </p>

            {activeButton ? (
              <ButtonBox
                rightArrowIcon
                type="primary"
                ghost
                label="Learn More"
                onClick={() => navigate.push("/about-us")}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
}
