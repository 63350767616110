import { Col, Row } from "antd";
import AssetPath from "../../AssetPath/AssetPath";
import PageTitle from "../../Components/PageTitle/PageTitle";
import CTA from "../LandingPage/CTA";
import { MapPin, ArrowUpRight } from "feather-icons-react";
import { useEffect, useState } from "react";
import apiClient from "../../Actions/apiClient";
import "./EventandRegistration.scss";

export default function EventandRegistration() {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    apiClient.get("events/list").then(({ data }) => {
      if (data && data.result) {
        // Sort events by updatedAt in descending order
        const sortedData = data.result.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        setDataSource(sortedData);
      }
    });
  }, []);

  const renderEventsSection = (title, events) => (
    <section className="spacing bg-white">
      <div className="container">
        <div className="text-center mb-3">
          <h4 className="title text-primary">{title}</h4>
        </div>
        <Row
          gutter={[
            { xs: 10, sm: 16, md: 24, lg: 30 },
            { xs: 10, sm: 16, md: 24, lg: 30 },
          ]}
          justify="center"
          align="middle"
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 18 }}
            lg={{ span: 18 }}
          >
            <div className="upcoming-events">
              {events.map((eventData, index) => (
                <div
                  className="events-list border card p-0 d-flex flex-column flex-md-row flex-lg-row  mt-3"
                  key={index}
                >
                  <div className="image position-relative overflow-hidden">
                    <span
                      style={{ left: "30px" }}
                      className="tags font-4 position-absolute bg-primary text-white bottom-0 font-weight-normal"
                    >
                      {eventData.eventDate}
                      {eventData.eventTime ? ` . ${eventData.eventTime}` : ""}
                    </span>
                    <img
                      src={eventData.thumbnail}
                      alt={eventData.title}
                      className="w-100"
                    />
                  </div>
                  <div className="details text-start">
                    <h3 className="text-primary font-1">{eventData.title}</h3>
                    <h4 className="text-black fst-italic font-2">
                      {eventData.subtitle}
                    </h4>
                    <div className="d-flex">
                      {eventData.priceinDollar && (
                        <div className="tag me-2">
                          $ {eventData.priceinDollar}
                        </div>
                      )}
                      {eventData.priceinRupees && (
                        <div className="tag">₹ {eventData.priceinRupees}</div>
                      )}
                    </div>
                    <p className="text-gray font-4 mb-3 mt-2 text-ellipsis3">
                      {eventData.shortDescription}
                    </p>
                    <div className="border-top text-gray pt-2">
                      <MapPin size={16} className="text-primary me-1" />
                      <span className="font-4">{eventData.location}</span>
                    </div>
                    <div className="buttons-links d-flex align-items-center flex-column flex-md-row flex-lg-row justify-content-start mt-3">
                      {eventData.eventLink1 && (
                        <a
                          href={eventData.eventLink1}
                          className="ant-btn ant-btn-primary"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Register and pay in USD
                          <ArrowUpRight size={16} />
                        </a>
                      )}
                      {eventData.eventLink2 && (
                        <a
                          href={eventData.eventLink2}
                          className="ant-btn ant-btn-secondary"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Register and pay in INR
                          <ArrowUpRight size={16} className="ms-2" />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );

  // Filter and sort upcoming and past events
  const upcomingEvents = dataSource
    .filter((data) => data.eventType === "UpcomingEvents")
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  const pastEvents = dataSource
    .filter((data) => data.eventType === "PastEvents")
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  return (
    <>
      <PageTitle
        mainTitle="Events and Registration"
        breadcrumbItems={[
          { name: "Events and Registration", path: "/events-and-registration" },
        ]}
        image={AssetPath.breadcrumb.eventandRegistration}
      />

      {upcomingEvents.length > 0 &&
        renderEventsSection("Upcoming Events", upcomingEvents)}
      {pastEvents.length > 0 && renderEventsSection("Past Events", pastEvents)}

      <section className="spacing bg-light-green">
        <div className="container">
          <Row
            gutter={[
              { xs: 10, sm: 16, md: 24, lg: 30 },
              { xs: 10, sm: 16, md: 24, lg: 30 },
            ]}
            justify="center"
            align="middle"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
              className="text-center"
            >
              <img
                src={AssetPath.images.ItcChennaiIndia}
                alt="Pharma-SOS - Commercial Operations Community"
                className="w-100"
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
            >
              <h4 className="title text-primary">
                ITC Grand Chola - A Premier Destination
              </h4>
              <p>
                Located in the heart of Chennai, ITC Grand Chola is a tribute to
                Southern India's rich heritage, offering 522 rooms, 78 serviced
                apartments, and 10 exquisite dining options. With grand
                conference, banquet, and exhibition spaces, it is ideal for both
                leisure and business guests.
              </p>
              <p>
                <strong className="text-primary">
                  Special Rates Available:
                </strong>{" "}
                Enjoy exclusive rates for Innovate Pharma 2024 conference
                attendees.
              </p>
              <a
                href="https://res.itchotels.com/?adult=1&arrive=2024-09-23&bookingTime=Same%20Day&chain=26676&child=0&config=Group&currency=INR&depart=2024-09-25&group=230924SOSPHA&hotel=30171&hotelID=30171&journey=undefined&level=hotel&locale=en-US&rooms=1&specialCode=230924SOSPHA&specialCodeType=group&stayLength=1&theme=Umbrella_chain"
                target="_blank"
                rel="noreferrer"
                className="ant-btn ant-btn-secondary"
              >
                Make Your Reservation
                <ArrowUpRight size={16} className="ms-2" />
              </a>
            </Col>
          </Row>
        </div>
      </section>

      <CTA
        title=""
        noImage
        subTitle="Join Our Board"
        label="Apply to Join"
        desc={
          <>
            <p>
              Are you passionate about building a professional community and
              expanding your network in the industry?
            </p>
            <p>
              We invite you to join the Pharma-SOS board and collaborate with
              leaders in the field. Reach out to learn more!
            </p>
          </>
        }
      />
    </>
  );
}
