import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import InnerRoutes from "./InnerRoutes";
import AuthenticatedRoutes from "./AuthenticatedRoutes";

class Routes extends React.Component {
  render() {
    return (
      <Router basename={process.env.REACT_APP_BASENAME || "/"}>
        <Switch>
          {/* Route for authenticated users */}
          <Route path={`/app`} component={AuthenticatedRoutes} />
          {/* Default route */}
          <Route path="/" component={InnerRoutes} />
        </Switch>
      </Router>
    );
  }
}

export default Routes;
