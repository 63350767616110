import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { BackTop } from "antd";
import { ArrowUp2 } from "iconsax-react";

import "./Footer.scss";
import FooterLinks from "./FooterLinks";
import AssetPath from "../AssetPath/AssetPath";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <React.Fragment>
      <section className="footer bg-footer py-4">
        <div className="container">
          <div className="footer-links d-flex flex-column flex-md-row flex-lg-row align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <img
                src={AssetPath.brand.pharmaSosWhite}
                alt="Pharma-SOS | Commercial Operations Community"
              />
            </div>
            {FooterLinks.map((data, i) => (
              <div className="links" key={i} data-aos="fade-up/">
                <ul className="d-flex flex-column flex-md-row flex-lg-row align-items-center justfy-content-between">
                  {data.links.map((list, i) => (
                    <li key={i}>
                      {list.icon ? (
                        <a
                          href={list.link}
                          target="_blank"
                          rel="noreferrer"
                          className="text-white"
                        >
                          {list.icon}
                          {list.title}
                        </a>
                      ) : (
                        <Link to={list.link} className="text-white">
                          {list.title}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="copyright-content d-flex flex-column flex-md-row flex-lg-row align-items-center justify-content-between">
            <div className="social-media d-flex align-items-center">
              {FooterLinks.map((data, i) => (
                <ul className="list-unstyled d-flex align-items-center" key={i}>
                  {data.socialMedia.map((social, i) => (
                    <li key={i}>
                      <a
                        href={social.url}
                        className="d-flex align-items-center justify-content-center"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {social.icon}
                      </a>
                    </li>
                  ))}
                </ul>
              ))}
            </div>

            <p className="mb-0 font-4 text-white">
              Copyright © {currentYear}{" "}
              <span className="font-weight-medium">Pharma-SOS</span>. All Rights
              Reserved.
            </p>
          </div>
        </div>
      </section>
      <BackTop>
        <div className="back-to-top">
          <ArrowUp2 size={15} />
        </div>
      </BackTop>
    </React.Fragment>
  );
}
