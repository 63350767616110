import { Col, Row } from "antd";
import React from "react";
import "../Contact/Contact.scss";
import PageTitle from "../../Components/PageTitle/PageTitle";
import AssetPath from "../../AssetPath/AssetPath";
import VolunteerForm from "./VolunteerForm";

export default function Volunteer() {
  return (
    <React.Fragment>
      <PageTitle
        mainTitle="Join us and make a difference!"
        breadcrumbItems={[
          {
            name: "Join us and make a difference!",
            path: "/become-a-volunteer",
          },
        ]}
        image={AssetPath.breadcrumb.becomeVolunteers}
      />
      <section className="spacing d-flex  align-items-center justify-content-center contact-title-section bg-white spacing bg-yellow-purple-gradient position-relative">
        <div className="overflow-hidden w-100">
          <div className="container">
            <div
              className="highlight-title mt-4 text-center"
              data-aos="fade-up"
            >
              {/* <span className="d-block sub-title text-uppercase font-2 text-primary">
                Join us and make a difference!
              </span> */}
              <p className="text-gray h4">
                Volunteer to assist our board with conferences and help create
                impactful events.{" "}
                <span className="d-block">
                  Your support can shape a better future.
                </span>
              </p>
              <p className="text-black mt-3">
                Fill out the form, and we'll be in touch.
              </p>
            </div>

            <div className="contact-ways">
              <Row
                gutter={[
                  { xs: 10, sm: 16, md: 24, lg: 32 },
                  { xs: 10, sm: 16, md: 24, lg: 20 },
                ]}
                className="pb-3 "
                align="middle"
                justify="center"
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <div
                    className="highlight-title border rounded card"
                    data-aos="fade-up"
                  >
                    <VolunteerForm />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
