import React from "react";
import { Formik, Field, Form } from "formik";
import apiClient from "../../Actions/apiClient";
import { useHistory } from "react-router-dom";
import { Col, Row, message } from "antd";
import { ArrowUpRight } from "feather-icons-react/build/IconComponents";

export default function NewsletterSubscription({ className }) {
  const history = useHistory();
  const onSend = (values) => {
    apiClient.post("subscribers/add", values).then(({ data }) => {
      if (data?.result) {
        message.success(
          "Thanks for your interest in Email Newsletter Subscription!"
        );
        history.push("/");
      }
    });
  };

  return (
    <section
      className={`newsletter bg-newsletter spacing position-relative overflow-hidden ${
        className || " "
      }`}
      data-aos="fade-up"
    >
      <div className="container">
        <Row
          gutter={[12, 24]}
          align="center"
          justify="middle"
          className="position-relative z-index"
        >
          <Col xs={24} sm={24} md={12} lg={12}>
            <div className="subscription">
              <div className="text-center">
                <h2 className="mb-3 text-capitalize text-white">
                  Stay Connected
                </h2>
                <p className="text-white">
                  Sign up to receive the latest updates on events directly to
                  your inbox.
                </p>
              </div>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                }}
                onSubmit={onSend}
              >
                <Form className="d-flex flex-column flex-md-row flex-lg-row align-items-center flex-row justify-content-between">
                  <Field
                    name="name"
                    required
                    placeholder="Full Name *"
                    type="text"
                    className="me-2"
                  />
                  <Field
                    name="email"
                    required
                    placeholder="Email Address *"
                    type="email"
                  />
                  <button
                    className="ant-btn ms-3 ant-btn-primary "
                    type="submit"
                  >
                    Subscribe Now{" "}
                    <span className="ms-1 arrow-right">
                      <ArrowUpRight size={18} />
                    </span>
                  </button>
                </Form>
              </Formik>
            </div>
          </Col>
        </Row>
      </div>
      {/* <img
        src={AssetPath.shape.brushStroke}
        alt="Become a Volunteer"
        className="position-absolute bottom-0 start-0 w-100"
      /> */}
    </section>
  );
}
