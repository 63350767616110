import { Button } from "antd";
import React, { Component } from "react";
import { ArrowUpRight } from "feather-icons-react";

class ButtonBox extends Component {
  componentDidMount() {
    this.props.refs?.(this);
  }

  componentWillUnmount() {
    this.props.refs?.(null);
  }

  onClick = () => {
    if (!this.props.loader) {
      this.props.onClick?.();
    }
  };

  render() {
    const {
      children,
      type,
      loader,
      style,
      label,
      leftIcon,
      rightArrowIcon,
      rightIcon,
      className,
      size,
      ghost,
      href,
    } = this.props;

    return (
      <Button
        style={style}
        onClick={this.onClick}
        type={type || "primary"}
        loading={loader}
        className={className}
        size={size}
        ghost={ghost}
        href={href}
      >
        <span className="me-1">{leftIcon}</span>
        {label}
        {children}
        <span className="ms-1">{rightIcon}</span>
        {rightArrowIcon ? (
          <span className="ms-1 arrow-right">
            <ArrowUpRight size={18} />
          </span>
        ) : (
          ""
        )}
      </Button>
    );
  }
}

export default ButtonBox;
