// innovate pharma gallery
import innovatePharma_1 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-1.jpg";
import innovatePharma_10 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-10.jpg";
import innovatePharma_100 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-100.jpg";
import innovatePharma_101 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-101.jpg";
import innovatePharma_102 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-102.jpg";
import innovatePharma_103 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-103.jpg";
import innovatePharma_104 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-104.jpg";
import innovatePharma_105 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-105.jpg";
import innovatePharma_106 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-106.jpg";
import innovatePharma_107 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-107.jpg";
import innovatePharma_108 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-108.jpg";
import innovatePharma_109 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-109.jpg";
import innovatePharma_11 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-11.jpg";
import innovatePharma_110 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-110.jpg";
import innovatePharma_111 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-111.jpg";
import innovatePharma_112 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-112.jpg";
import innovatePharma_113 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-113.jpg";
import innovatePharma_114 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-114.jpg";
import innovatePharma_115 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-115.jpg";
import innovatePharma_116 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-116.jpg";
import innovatePharma_117 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-117.jpg";
import innovatePharma_118 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-118.jpg";
import innovatePharma_119 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-119.jpg";
import innovatePharma_12 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-12.jpg";
import innovatePharma_120 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-120.jpg";
import innovatePharma_121 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-121.jpg";
import innovatePharma_122 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-122.jpg";
import innovatePharma_123 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-123.jpg";
import innovatePharma_124 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-124.jpg";
import innovatePharma_125 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-125.jpg";
import innovatePharma_126 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-126.jpg";
import innovatePharma_127 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-127.jpg";
import innovatePharma_128 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-128.jpg";
import innovatePharma_129 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-129.jpg";
import innovatePharma_13 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-13.jpg";
import innovatePharma_130 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-130.jpg";
import innovatePharma_131 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-131.jpg";
import innovatePharma_132 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-132.jpg";
import innovatePharma_133 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-133.jpg";
import innovatePharma_134 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-134.jpg";
import innovatePharma_135 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-135.jpg";
import innovatePharma_136 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-136.jpg";
import innovatePharma_137 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-137.jpg";
import innovatePharma_138 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-138.jpg";
import innovatePharma_139 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-139.jpg";
import innovatePharma_14 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-14.jpg";
import innovatePharma_140 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-140.jpg";
import innovatePharma_141 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-141.jpg";
import innovatePharma_142 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-142.jpg";
import innovatePharma_143 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-143.jpg";
import innovatePharma_144 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-144.jpg";
import innovatePharma_15 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-15.jpg";
import innovatePharma_16 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-16.jpg";
import innovatePharma_17 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-17.jpg";
import innovatePharma_18 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-18.jpg";
import innovatePharma_19 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-19.jpg";
import innovatePharma_2 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-2.jpg";
import innovatePharma_20 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-20.jpg";
import innovatePharma_21 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-21.jpg";
import innovatePharma_22 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-22.jpg";
import innovatePharma_23 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-23.jpg";
import innovatePharma_24 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-24.jpg";
import innovatePharma_25 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-25.jpg";
import innovatePharma_26 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-26.jpg";
import innovatePharma_27 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-27.jpg";
import innovatePharma_28 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-28.jpg";
import innovatePharma_29 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-29.jpg";
import innovatePharma_3 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-3.jpg";
import innovatePharma_30 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-30.jpg";
import innovatePharma_31 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-31.jpg";
import innovatePharma_32 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-32.jpg";
import innovatePharma_33 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-33.jpg";
import innovatePharma_34 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-34.jpg";
import innovatePharma_35 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-35.jpg";
import innovatePharma_36 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-36.jpg";
import innovatePharma_37 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-37.jpg";
import innovatePharma_38 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-38.jpg";
import innovatePharma_39 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-39.jpg";
import innovatePharma_4 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-4.jpg";
import innovatePharma_40 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-40.jpg";
import innovatePharma_41 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-41.jpg";
import innovatePharma_42 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-42.jpg";
import innovatePharma_43 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-43.jpg";
import innovatePharma_44 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-44.jpg";
import innovatePharma_45 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-45.jpg";
import innovatePharma_46 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-46.jpg";
import innovatePharma_47 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-47.jpg";
import innovatePharma_48 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-48.jpg";
import innovatePharma_49 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-49.jpg";
import innovatePharma_5 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-5.jpg";
import innovatePharma_50 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-50.jpg";
import innovatePharma_51 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-51.jpg";
import innovatePharma_52 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-52.jpg";
import innovatePharma_53 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-53.jpg";
import innovatePharma_54 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-54.jpg";
import innovatePharma_55 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-55.jpg";
import innovatePharma_56 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-56.jpg";
import innovatePharma_57 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-57.jpg";
import innovatePharma_58 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-58.jpg";
import innovatePharma_59 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-59.jpg";
import innovatePharma_6 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-6.jpg";
import innovatePharma_60 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-60.jpg";
import innovatePharma_61 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-61.jpg";
import innovatePharma_62 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-62.jpg";
import innovatePharma_63 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-63.jpg";
import innovatePharma_64 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-64.jpg";
import innovatePharma_65 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-65.jpg";
import innovatePharma_66 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-66.jpg";
import innovatePharma_67 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-67.jpg";
import innovatePharma_68 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-68.jpg";
import innovatePharma_69 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-69.jpg";
import innovatePharma_7 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-7.jpg";
import innovatePharma_70 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-70.jpg";
import innovatePharma_71 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-71.jpg";
import innovatePharma_72 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-72.jpg";
import innovatePharma_73 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-73.jpg";
import innovatePharma_74 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-74.jpg";
import innovatePharma_75 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-75.jpg";
import innovatePharma_76 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-76.jpg";
import innovatePharma_77 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-77.jpg";
import innovatePharma_78 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-78.jpg";
import innovatePharma_79 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-79.jpg";
import innovatePharma_8 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-8.jpg";
import innovatePharma_80 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-80.jpg";
import innovatePharma_81 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-81.jpg";
import innovatePharma_82 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-82.jpg";
import innovatePharma_83 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-83.jpg";
import innovatePharma_84 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-84.jpg";
import innovatePharma_85 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-85.jpg";
import innovatePharma_86 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-86.jpg";
import innovatePharma_87 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-87.jpg";
import innovatePharma_88 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-88.jpg";
import innovatePharma_89 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-89.jpg";
import innovatePharma_9 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-9.jpg";
import innovatePharma_90 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-90.jpg";
import innovatePharma_91 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-91.jpg";
import innovatePharma_92 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-92.jpg";
import innovatePharma_93 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-93.jpg";
import innovatePharma_94 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-94.jpg";
import innovatePharma_95 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-95.jpg";
import innovatePharma_96 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-96.jpg";
import innovatePharma_97 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-97.jpg";
import innovatePharma_98 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-98.jpg";
import innovatePharma_99 from "../assets/images/gallery/innovate-pharma/innovate-pharma-2024-99.jpg";

import BalasubramanianSankaranarayananDoc from "../assets/presentations/innovate-pharma/Balasubramanian-Sankaranarayanan.pdf";
import DanielRoseDoc from "../assets/presentations/innovate-pharma/Daniel-Rose.pdf";
import DrSantoshTaurDoc from "../assets/presentations/innovate-pharma/Dr-Santosh-Taur.pdf";
import HarpreetSinghRajanDuaDoc from "../assets/presentations/innovate-pharma/Harpreet-Singh-Rajan-Dua.pdf";
import JisaSusanBajiDoc from "../assets/presentations/innovate-pharma/Jisa-Susan-Baji.pdf";
import LathaSrinivasanDoc from "../assets/presentations/innovate-pharma/Latha-Srinivasan.pdf";
import MohitSoodDoc from "../assets/presentations/innovate-pharma/Mohit-Sood.pdf";
import PanelDiscussionWhatisCriticalforSuccessofGenAIDoc from "../assets/presentations/innovate-pharma/Panel-Discussion-What-is-Critical-for-Success-of-GenAI.pdf";
import PrakashKarakaSuzanneMarzziottiDoc from "../assets/presentations/innovate-pharma/Prakash-Karaka-Suzanne-Marzziotti.pdf";
import RaghavendranJayasubramanianDayDoc from "../assets/presentations/innovate-pharma/Raghavendran-Jayasubramanian-day-2.pdf";
import RaghavendranJayasubramanianDoc from "../assets/presentations/innovate-pharma/Raghavendran-Jayasubramanian.pdf";
import RaghuveeranSowmyanarayananDoc from "../assets/presentations/innovate-pharma/Raghuveeran-Sowmyanarayanan.pdf";
import SiddharthMandalDoc from "../assets/presentations/innovate-pharma/Siddharth-Mandal.pdf";

import SivaKumarPadmanabhanDoc from "../assets/presentations/innovate-pharma/Siva-Kumar-Padmanabhan.pdf";
import SomsekharPalDoc from "../assets/presentations/innovate-pharma/Somsekhar-Pal.pdf";
import SudharsanMohanDoc from "../assets/presentations/innovate-pharma/Sudharsan-Mohan.pdf";
import VenkatVishwanathanDoc from "../assets/presentations/innovate-pharma/Venkat-Vishwanathan.pdf";
import VigneshKrishnakumarDoc from "../assets/presentations/innovate-pharma/Vignesh-Krishnakumar.pdf";
import VikasGadeChetanKulkarniDoc from "../assets/presentations/innovate-pharma/Vikas-Gade-Chetan-Kulkarni.pdf";

const InnovatePharmaPath = {
  Gallery: {
    innovatePharma_1,
    innovatePharma_10,
    innovatePharma_100,
    innovatePharma_101,
    innovatePharma_102,
    innovatePharma_103,
    innovatePharma_104,
    innovatePharma_105,
    innovatePharma_106,
    innovatePharma_107,
    innovatePharma_108,
    innovatePharma_109,
    innovatePharma_11,
    innovatePharma_110,
    innovatePharma_111,
    innovatePharma_112,
    innovatePharma_113,
    innovatePharma_114,
    innovatePharma_115,
    innovatePharma_116,
    innovatePharma_117,
    innovatePharma_118,
    innovatePharma_119,
    innovatePharma_12,
    innovatePharma_120,
    innovatePharma_121,
    innovatePharma_122,
    innovatePharma_123,
    innovatePharma_124,
    innovatePharma_125,
    innovatePharma_126,
    innovatePharma_127,
    innovatePharma_128,
    innovatePharma_129,
    innovatePharma_13,
    innovatePharma_130,
    innovatePharma_131,
    innovatePharma_132,
    innovatePharma_133,
    innovatePharma_134,
    innovatePharma_135,
    innovatePharma_136,
    innovatePharma_137,
    innovatePharma_138,
    innovatePharma_139,
    innovatePharma_14,
    innovatePharma_140,
    innovatePharma_141,
    innovatePharma_142,
    innovatePharma_143,
    innovatePharma_144,
    innovatePharma_15,
    innovatePharma_16,
    innovatePharma_17,
    innovatePharma_18,
    innovatePharma_19,
    innovatePharma_2,
    innovatePharma_20,
    innovatePharma_21,
    innovatePharma_22,
    innovatePharma_23,
    innovatePharma_24,
    innovatePharma_25,
    innovatePharma_26,
    innovatePharma_27,
    innovatePharma_28,
    innovatePharma_29,
    innovatePharma_3,
    innovatePharma_30,
    innovatePharma_31,
    innovatePharma_32,
    innovatePharma_33,
    innovatePharma_34,
    innovatePharma_35,
    innovatePharma_36,
    innovatePharma_37,
    innovatePharma_38,
    innovatePharma_39,
    innovatePharma_4,
    innovatePharma_40,
    innovatePharma_41,
    innovatePharma_42,
    innovatePharma_43,
    innovatePharma_44,
    innovatePharma_45,
    innovatePharma_46,
    innovatePharma_47,
    innovatePharma_48,
    innovatePharma_49,
    innovatePharma_5,
    innovatePharma_50,
    innovatePharma_51,
    innovatePharma_52,
    innovatePharma_53,
    innovatePharma_54,
    innovatePharma_55,
    innovatePharma_56,
    innovatePharma_57,
    innovatePharma_58,
    innovatePharma_59,
    innovatePharma_6,
    innovatePharma_60,
    innovatePharma_61,
    innovatePharma_62,
    innovatePharma_63,
    innovatePharma_64,
    innovatePharma_65,
    innovatePharma_66,
    innovatePharma_67,
    innovatePharma_68,
    innovatePharma_69,
    innovatePharma_7,
    innovatePharma_70,
    innovatePharma_71,
    innovatePharma_72,
    innovatePharma_73,
    innovatePharma_74,
    innovatePharma_75,
    innovatePharma_76,
    innovatePharma_77,
    innovatePharma_78,
    innovatePharma_79,
    innovatePharma_8,
    innovatePharma_80,
    innovatePharma_81,
    innovatePharma_82,
    innovatePharma_83,
    innovatePharma_84,
    innovatePharma_85,
    innovatePharma_86,
    innovatePharma_87,
    innovatePharma_88,
    innovatePharma_89,
    innovatePharma_9,
    innovatePharma_90,
    innovatePharma_91,
    innovatePharma_92,
    innovatePharma_93,
    innovatePharma_94,
    innovatePharma_95,
    innovatePharma_96,
    innovatePharma_97,
    innovatePharma_98,
    innovatePharma_99,
  },
  Doc: {
    BalasubramanianSankaranarayananDoc,
    DanielRoseDoc,
    DrSantoshTaurDoc,
    HarpreetSinghRajanDuaDoc,
    JisaSusanBajiDoc,
    LathaSrinivasanDoc,
    MohitSoodDoc,
    PanelDiscussionWhatisCriticalforSuccessofGenAIDoc,
    PrakashKarakaSuzanneMarzziottiDoc,
    RaghavendranJayasubramanianDayDoc,
    RaghavendranJayasubramanianDoc,
    RaghuveeranSowmyanarayananDoc,
    SiddharthMandalDoc,
    SivaKumarPadmanabhanDoc,
    SomsekharPalDoc,
    SudharsanMohanDoc,
    VenkatVishwanathanDoc,
    VigneshKrishnakumarDoc,
    VikasGadeChetanKulkarniDoc,
  },
};

export default InnovatePharmaPath;
