import React, { useRef, useState } from "react";
import { Carousel, Col, Row } from "antd";
import "./Home.scss";
import HomeSliderData from "./HomeSliderData";
import { useHistory } from "react-router-dom";
import "./HomeSlider.scss";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";
import AssetPath from "../../AssetPath/AssetPath";
import AboutSection from "./AboutSection";
import NewsletterSubscription from "../SubscribeNewsletter/NewsletterSubscription";
import ExpertTeam from "./ExpertTeam";
import ContactSectionForm from "./ContactSectionForm";
import { ChevronLeft, ChevronRight } from "feather-icons-react";
import ModalBox from "../../Components/ModalBox/ModalBox";
import SubmitAbstractForm from "./SubmitAbstractForm";
import OurPartners from "./OurPartners";

export default function Home() {
  const [open, setOpen] = useState();

  const navigate = useHistory();

  const carouselRef = useRef();

  const handleNext = () => {
    carouselRef.current.next();
  };

  const handlePrevious = () => {
    carouselRef.current.prev();
  };

  const carouselConfig = {
    draggable: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2500,
    infinite: true,
    slidesToShow: 1,
    swipeToSlide: true,
  };

  return (
    <>
      <section
        className="home-sldier bg-light-blue position-relative overflow-hidden"
        data-aos="fade-up"
      >
        <div className="slide-navigations position-absolute d-flex">
          <div
            className="previous me-2 cursor-pointer"
            onClick={handlePrevious}
          >
            <ChevronLeft size={18} className="text-primary" />
          </div>
          <div className="next cursor-pointer" onClick={handleNext}>
            <ChevronRight size={18} className="text-primary" />
          </div>
        </div>
        <Carousel {...carouselConfig} ref={carouselRef}>
          {HomeSliderData.map((data, i) => (
            <div
              className={`slider-list position-relative d-flex flex-column flex-lg-row align-items-center justiyf-content-center ${data.className}`}
              key={i}
            >
              <div className="slide-content" data-aos="fade-up">
                <h1 className="mb-0 text-primary">{data.title}</h1>
                {data.secondTitle ? (
                  <h3 className="mb-0 text-gray font-weight-bold h4 fst-italic">
                    {data.secondTitle}
                  </h3>
                ) : (
                  ""
                )}
                <h2 className="text-primary">{data.subTitle}</h2>
                <h5 className="text-gray text-uppercase font-weight-medium">
                  {data.dateTime}
                </h5>
                {data.shortDescription ? (
                  <p className="text-gray">{data.shortDescription}</p>
                ) : (
                  ""
                )}
                <ButtonBox
                  type="primary"
                  label={data.btnText}
                  onClick={() => navigate.push(`${data.url}`)}
                  rightArrowIcon
                />
              </div>

              <div className="main-image text-end overflow-hidden position-relative">
                <div
                  className={`gradient position-absolute ${data.gradient}`}
                  style={{ width: "30%", height: "100%", left: "-3px" }}
                />
                <img src={data.thumbnail} alt={data.title} />
              </div>
            </div>
          ))}
        </Carousel>
      </section>

      <AboutSection activeButton />

      <section
        className="spacing become-volunteer bg-volunteer position-relative"
        data-aos="fade-up"
      >
        {/* <img
          src={AssetPath.shape.brushStrokeGreen}
          alt="Become a Volunteer"
          className="position-absolute bottom-0 start-0 w-100"
        /> */}
        <div className="container text-center position-relative ">
          <h2 className="mb-1 text-capitalize text-white">
            Join us and make a difference!
          </h2>
          <p className="text-white text-center ">
            Volunteer to assist our board with conferences and help create
            impactful events.{" "}
            <span className="d-block">
              Your support can shape a better future.
            </span>
          </p>
          <h4 className="text-white mb-3 text-center">Ready to contribute?</h4>
          <div className="pb-4">
            <ButtonBox
              label="Become a Volunteer"
              rightArrowIcon
              type="default"
              onClick={() => navigate.push("/become-a-volunteer")}
            />
          </div>
        </div>
      </section>

      {/* <section className="spacing bg-light-green">
        <div className="container ">
          <Row
            gutter={[
              { xs: 10, sm: 16, md: 24, lg: 24 },
              { xs: 10, sm: 16, md: 24, lg: 24 },
            ]}
            justify="center"
            align="middle"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
            >
              <h3 className="sub-title text-uppercase font-2 text-primary">
                Upcoming events
              </h3>
              <h4 className="title mb-3 text-primary">
                Ready to join our latest upcoming events
              </h4>
              <p className="text-gray">
                Join us at the "Innovate Pharma 2024 | Bridging Continents for a
                Healthier Tomorrow" for insightful discussions on India's future
                in global business, technology, and culture. Network with
                industry leaders and gain invaluable knowledge from expert
                speakers. Don't miss this opportunity to be part of India's
                growth story—register today!
              </p>
              <ButtonBox
                rightArrowIcon
                type="secondary"
                label="Register Now"
                onClick={() => navigate.push("/events-and-registration")}
              />
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
              className="text-center"
            >
              <EvenetsList />
            </Col>
          </Row>
        </div>
      </section> */}

      <ExpertTeam />

      <NewsletterSubscription className="before-content-black" />

      <OurPartners />

      <section className="contact-section  spacing" data-aos="fade-up">
        <div className="container">
          <div className="text-center">
            <h4 className="title text-capitalize mb-4 text-primary">
              We would love to hear from you
            </h4>
          </div>
          <Row
            gutter={[
              { xs: 10, sm: 16, md: 24, lg: 30 },
              { xs: 10, sm: 16, md: 24, lg: 30 },
            ]}
            justify="center"
            align="middle"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
            >
              <img
                src={AssetPath.images.contactBg}
                alt="Pharma-SOS - Commercial Operations Community"
                className="rounded mb-3 w-100"
                data-aos="fade-up"
              />
              <p className="text-gray" data-aos="fade-up">
                For more information or scholarship inquiries, send us a
                message, and we’ll respond promptly.
              </p>
              <p className="font-weight-medium text-primary" data-aos="fade-up">
                For press passes or special requests, please complete the form
                and submit it.
              </p>

              {/* <div className="phone-and-address">
                {contactData.map((data, i) => (
                  <div
                    className="contact-list d-flex align-items-center justify-content-start mt-2"
                    key={i}
                  >
                    <div className="icon rounded-circle border d-flex align-items-center justify-content-center me-2">
                      {data.icon}
                    </div>
                    <div className="details">
                      <span className="d-block text-black font-weight-bold mb-0">
                        {data.title}
                      </span>
                      <p className="text-gray mb-0">{data.content}</p>
                    </div>
                  </div>
                ))}
              </div> */}
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 9 }}
              lg={{ span: 9 }}
            >
              <div className="card">
                <ContactSectionForm />
              </div>
            </Col>
          </Row>
        </div>
      </section>

      {/* submit abstract details */}
      <ModalBox
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={null}
        width={900}
      >
        <SubmitAbstractForm />
      </ModalBox>
    </>
  );
}
