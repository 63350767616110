import InnovatePharmaPath from "../../AssetPath/InnovatePharmaPath";

const InnovatePharmaEventDetails = [
  {
    title:
      "KEYNOTE: A Patient's Perspective and Expectations from Healthcare System",
    path: InnovatePharmaPath.Doc.LathaSrinivasanDoc,
    speaker: "Latha Srinivasan",
    role: "Author",
    company: "",
  },
  {
    title:
      "KEYNOTE: Setting and scaling a GCC in India: Reflections of a Journey",
    path: InnovatePharmaPath.Doc.SivaKumarPadmanabhanDoc,
    speaker: "Siva Kumar Padmanabhan",
    role: "MD & Head of Global Innovation & Technology Center",
    company: "AstraZeneca India",
  },
  {
    title:
      "Presentation: Getting Started with a GCC; Trigger and Tactics for Success",
    path: InnovatePharmaPath.Doc.DanielRoseDoc,
    speaker: "Daniel Rose",
    role: "VP, Insulet Global Business Services",
    company: "Insulet Corporation",
  },
  {
    title:
      "Presentation: Medical Affairs: Bridging the gap between Commercial Operations and Patients, Role of R&D in Pharma industry",
    path: InnovatePharmaPath.Doc.DrSantoshTaurDoc,
    speaker: "Dr. Santosh Taur",
    role: "Director Medical Affairs",
    company: "Pfizer",
  },
  {
    title:
      "Presentation: Patient-Centric Analytics: Enhancing Outcomes and Revenue through Data-Driven Insights",
    path: InnovatePharmaPath.Doc.MohitSoodDoc,
    speaker: "Mohit Sood",
    role: "Regional Managing Principal",
    company: "ZS India",
  },
  {
    title:
      "Presentation: Evolving Beyond Support; building World Class Customer Experience Centre",
    path: InnovatePharmaPath.Doc.VenkatVishwanathanDoc,
    speaker: "Venkat Vishwanathan",
    role: "Chief People Officer",
    company: "Roche",
  },
  {
    title:
      "Presentation: Evolving with Purpose: Integrating GCC Capabilities along the Data Value Chain",
    path: InnovatePharmaPath.Doc.VigneshKrishnakumarDoc,
    speaker: "Vignesh Krishnakumar",
    role: "Senior Manager, GATE",
    company: "Roche",
  },
  {
    title: "Ted Talk: Bridging the 8K mile divide",
    path: InnovatePharmaPath.Doc.JisaSusanBajiDoc,
    speaker: "Jisa Baji",
    role: "Global Head of Insights and Analytics",
    company: "Sandoz",
  },
  {
    title:
      "Presentation: Data Stacking in Action: Transforming Projects for Superior Results",
    path: InnovatePharmaPath.Doc.VikasGadeChetanKulkarniDoc,
    speaker: "Vikas Gade & Chetan Kulkarni",
    role: "Lead Business Analytics & Executive Director",
    company: "Chryselys",
  },
  {
    title: "Presentation: Improved Field Coaching by Leveraging Gen AI",
    path: InnovatePharmaPath.Doc.RaghavendranJayasubramanianDoc,
    speaker: "Raghavendran Jayasubramanian",
    role: "Group Head - Data Science",
    company: "Novartis",
  },
  {
    title:
      "Presentation: Navigating The Future: Adapting To Technological Innovations In Pharma",
    path: InnovatePharmaPath.Doc.BalasubramanianSankaranarayananDoc,
    speaker: "Bala Sankaranarayanan",
    role: "CEO",
    company: "Thryve Digital",
  },
  {
    title:
      "Panel Discussion: Where is Gen AI in the Gartner's Hype Cycle, and What is Critical for Success",
    path: InnovatePharmaPath.Doc.RaghavendranJayasubramanianDayDoc,
    speaker: "Raghavendran Jayasubramanian",
    role: "Group Head - Data Science",
    company: "Novartis",
  },
  {
    title:
      "Panel Discussion: Where is Gen AI in the Gartner's Hype Cycle, and What is Critical for Success",
    path: InnovatePharmaPath.Doc.SiddharthMandalDoc,
    speaker: "Siddharth Mandal",
    role: "Senior Manager, GATE",
    company: "Roche",
  },
  {
    title: "Presentation: Getting Started on Gen AI",
    path: InnovatePharmaPath.Doc.PrakashKarakaSuzanneMarzziottiDoc,
    speaker: "Prakash Karaka",
    role: "Chief AI Officer",
    company: "Chryselys",
  },
  {
    title: "Presentation: Experience with Optimizing LLM Based GenAI Solutions",
    path: InnovatePharmaPath.Doc.SudharsanMohanDoc,
    speaker: "Sudharsan Mohan",
    role: "Associate Director-Products",
    company: "Chryselys",
  },
  {
    title: "Presentation: Building and Scaling Gen AI COEs",
    path: InnovatePharmaPath.Doc.HarpreetSinghRajanDuaDoc,
    speaker: ["Harpreet Singh", "Rajan Dua"],
    role: "Co-founder & Senior Engagement Lead",
    company: "ProcDNA",
  },
  {
    title:
      "Presentation: Leveraging GenAI for the Healthcare Spectrum Across Patients, Provider, and Payer",
    path: InnovatePharmaPath.Doc.RaghuveeranSowmyanarayananDoc,
    speaker: "Raghuveeran Sowmyanarayanan",
    role: "Global AI Delivery Head",
    company: "Wipro Technologies",
  },
  {
    title: "Presentation: Elevating Rep and HCP Experience Through AI",
    path: InnovatePharmaPath.Doc.SomsekharPalDoc,
    speaker: "Somsekhar Pal",
    role: "Principal",
    company: "ZS India",
  },
];

export default InnovatePharmaEventDetails;
