import { Col, Image, Row } from "antd";
import AssetPath from "../../AssetPath/AssetPath";
import PageTitle from "../../Components/PageTitle/PageTitle";
import GalleryData from "./GalleryData";
import { useHistory } from "react-router-dom";
import LazyLoad from "react-lazyload";

const InnovatePhramaGallery = () => {
  const navigate = useHistory();

  return (
    <>
      <PageTitle
        mainTitle="Innovate Pharma 2024"
        breadcrumbItems={[{ name: "Gallery", path: "/gallery" }]}
        image={AssetPath.breadcrumb.innovatePhrama2024}
      />

      <section className="spacing">
        <div className="container">
          <div className="text-center mb-2">
            <h2 className="text-primary h3 mb-1">Innovate Pharma 2024</h2>
            <h4 className="fw-normal fst-italic">
              Bridging Continents for a Healthier Tomorrow
            </h4>
          </div>
          {GalleryData[0]?.innovatePharma && (
            <Row gutter={[16, 24]}>
              {GalleryData[0].innovatePharma.images.map((image, i) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                  key={i}
                >
                  <div className="gallery-details card border p-2">
                    <LazyLoad height={200}>
                      <Image
                        src={image}
                        alt={`Innovate Pharma 2024`}
                        className="w-100 mb-2"
                        loading="lazy"
                      />
                    </LazyLoad>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </div>
      </section>
    </>
  );
};

export default InnovatePhramaGallery;
