import React, { useState } from "react";
import CTA from "../LandingPage/CTA";
import PageTitle from "../../Components/PageTitle/PageTitle";
import AssetPath from "../../AssetPath/AssetPath";
import ModalBox from "../../Components/ModalBox/ModalBox";
import SubmitAbstractForm from "../LandingPage/SubmitAbstractForm";
import { Link, useHistory } from "react-router-dom";
import SponsorPackages from "../Sponsors/SponsorPackages";
import AgendaList from "../Agenda/AgendaList";
import Speakers from "../Agenda/Speakers";
import InnovatePharmaEventDetails from "../Agenda/InnovatePharmaEventDetails";
import GalleryData from "../Gallery/GalleryData";
import { Image, Col, Row } from "antd";
import LazyLoad from "react-lazyload";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";

export default function InnovatePharma2024() {
  const [open, setOpen] = useState();
  const history = useHistory();

  return (
    <>
      <PageTitle
        mainTitle="Innovate pharma 2024"
        breadcrumbItems={[
          {
            name: "Innovate pharma 2024",
            path: "/innovate-pharma-2024-bridging-continents-for-a-healthier-tomorrow",
          },
        ]}
        image={AssetPath.breadcrumb.innovatePhrama2024}
        strokeType="green"
      />
      <AgendaList />

      <section
        className="presentations our-team spacing position-relative bg-light-green"
        data-aos="fade-up"
      >
        <div className="container">
          <h3 className="text-primary text-center">
            Innovate Pharma 2024 Presentation
          </h3>
          <ul className="list-unstyled">
            {InnovatePharmaEventDetails.map((data, i) => (
              <li>
                <a
                  href={data.path}
                  target="_blank"
                  className="d-flex align-items-center   border rounded mb-2"
                >
                  <div className="count d-flex align-items-center justify-content-center font-4 me-3 bg-primary text-white">
                    {i + 1}
                  </div>
                  <div className="text-left">
                    <h5 className="d-block w-100 mb-0">{data.title}</h5>
                    <div className="d-block">
                      <p className="mb-0">
                        <strong>{data.speaker}</strong>• <em>{data.role}</em>{" "}
                        {data.company ? " •" : ""}{" "}
                        <strong>{data.company} </strong>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <Speakers />
      <section className="spacing bg-light-green">
        <div className="container">
          <div className="text-center mb-2">
            <h4 className="title mb-3 text-primary text-center">Gallery</h4>
          </div>
          {GalleryData[0]?.innovatePharma && (
            <Row gutter={[16, 24]}>
              {GalleryData[0].innovatePharma.images
                .slice(0, 12)
                .map((image, i) => (
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                    key={i}
                  >
                    <div className="gallery-details card border p-2">
                      <LazyLoad height={200}>
                        <Image
                          src={image}
                          alt={`Innovate Pharma 2024`}
                          className="w-100 mb-2"
                          loading="lazy"
                        />
                      </LazyLoad>
                    </div>
                  </Col>
                ))}
            </Row>
          )}

          <div className="text-center mt-3">
            <ButtonBox
              variant="primary"
              label="Load More Images"
              onClick={() =>
                history.push(
                  "/innovate-pharma-2024-bridging-continents-for-a-healthier-tomorrow-gallery"
                )
              }
              rightArrowIcon
            />
          </div>
        </div>
      </section>

      <ModalBox
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={null}
        width={900}
      >
        <SubmitAbstractForm />
      </ModalBox>
    </>
  );
}
