import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../screens/LandingPage/Home";
import Contact from "../screens/Contact/Contact";
import AboutUs from "../screens/AboutUs/AboutUs";
import Layout from "../Layout/Layout";
import FAQ from "../screens/FAQ/FAQ";
import Login from "../screens/Login/Login";

// privacy info
import Privacy from "../screens/Privacy/Privacy";
import TermsandConditions from "../screens/TermsandConditions/TermsandConditions";
import PageNotFound from "../screens/PageNotFound/PageNotFound";

import Careers from "../screens/Careers/Careers";
import Sponsors from "../screens/Sponsors/Sponsors";
import EventandRegistration from "../screens/EventandRegistration/EventandRegistration";
import Agenda from "../screens/Agenda/Agenda";
import Volunteer from "../screens/Volunteer/Volunteer";
import SubmitAbstract from "../screens/SubmitAbstract/SubmitAbstract";

// gallery page
import Gallery from "../screens/Gallery/Gallery";
import InnovatePhramaGallery from "../screens/Gallery/InnovatePhramaGallery";
import InnovatePharma2024 from "../screens/PastEvents/InnovatePharma2024";
import PastEvents from "../screens/EventandRegistration/PastEvents";

class InnerRoutes extends React.Component {
  render() {
    const {
      match: { url },
    } = this.props;
    return (
      <Layout {...this.props}>
        <Switch>
          <Route exact path={`/`} component={Home} />
          <Route path={`/contact-us`} component={Contact} />
          <Route path={`/faq`} component={FAQ} />
          <Route path={`/contact-us`} component={Contact} />
          <Route path={`/about-us`} component={AboutUs} />
          <Route path={`/sponsors`} component={Sponsors} />
          <Route
            path={`/events-and-registration`}
            component={EventandRegistration}
          />
          <Route path={`/agenda`} component={Agenda} />
          <Route path={`/become-a-volunteer`} component={Volunteer} />

          {/* privacy info */}
          <Route path={`/privacy-policy`} component={Privacy} />
          <Route
            path={`/terms-and-conditions`}
            component={TermsandConditions}
          />
          <Route path="/career" component={Careers} />
          {/* blogs */}

          {/* gallery page */}
          <Route
            path={`/innovate-pharma-2024-bridging-continents-for-a-healthier-tomorrow`}
            component={InnovatePharma2024}
          />
          <Route
            path={`/innovate-pharma-2024-bridging-continents-for-a-healthier-tomorrow-gallery`}
            component={InnovatePhramaGallery}
          />
          <Route path={`/gallery`} component={Gallery} />
          <Route path={`/abstract-submission`} component={SubmitAbstract} />

          <Route path={`/past-events`} component={PastEvents} />

          <Route path={`/login`} component={Login} />

          {/* page not found */}
          <Route path={`*`} component={PageNotFound} />
        </Switch>
      </Layout>
    );
  }
}

export default InnerRoutes;
