import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import "./PageTitle.scss";
import AssetPath from "../../AssetPath/AssetPath";

export default function PageTitle({
  image,
  mainTitle,
  longTitle,
  breadcrumbItems,
  mainDesc,
  strokeType,
}) {
  const items = [{ name: "Pharma-SOS", path: "/" }, ...breadcrumbItems];
  return (
    <>
      <section
        className="breadcrum-placehlder position-relative before-content-primary"
        style={{ backgroundImage: `url(${image})` }}
      >
        {/* {strokeType === "green" ? (
          <img
            src={AssetPath.shape.brushStrokeGreen}
            alt="Pharma-SOS | Commercial Operations Community"
            className="position-absolute bottom-0 start-0 w-100"
          />
        ) : (
          <img
            src={AssetPath.shape.brushStrokeWhite}
            alt="Pharma-SOS | Commercial Operations Community"
            className="position-absolute bottom-0 start-0 w-100"
          />
        )} */}

        <div className="container">
          <div className="content" data-aos="fade-right">
            <div>
              <h1 className="text-white">
                {mainTitle} <span className="text-green">{longTitle}</span>
              </h1>
              {mainDesc ? <p className="text-white">{mainDesc}</p> : ""}

              <div className="page-path">
                {items.length > 0 ? (
                  <Breadcrumb>
                    {items.map((item, i) => (
                      <Breadcrumb.Item key={i}>
                        <Link to={item.path}>{item.name}</Link>
                      </Breadcrumb.Item>
                    ))}
                  </Breadcrumb>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
