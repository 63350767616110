import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../Contact/Contact.scss";
import apiClient from "../../Actions/apiClient";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import { ArrowUpRight } from "feather-icons-react";
import AssetPath from "../../AssetPath/AssetPath";

export default function SubmitAbstractForm() {
  const history = useHistory();

  const handleFileChange = async (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        // 10 MB
        message.error("File size exceeds 10 MB limit.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setFieldValue("abstractFile", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onSend = (values) => {
    apiClient.post("abstract/add", values).then(({ data }) => {
      if (data?.result) {
        message.success(
          "Thanks for Submitting Abstract! Our team will reach out to you shortly"
        );
        history.push("/");
      }
    });
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Full Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    abstractFile: Yup.string().required("Abstract file is required"),
    message: Yup.string().required("Message is required"),
  });

  return (
    <div className="abstract-submission">
      <div className="image">
        <img src={AssetPath.events.chennaiEvents} alt="Abstract Submission" />
      </div>
      <div className="details">
        <div className="contact-form">
          <Formik
            initialValues={{
              name: "",
              email: "",
              company: "",
              phone: "",
              subject: "",
              abstractFile: "",
              message: "",
            }}
            validationSchema={validationSchema}
            onSubmit={onSend}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="row">
                  <div className="field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <label>Full Name *</label>
                    <Field
                      name="name"
                      // placeholder="Enter your full name.."
                      type="text"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <label>Email Address *</label>
                    <Field
                      name="email"
                      // placeholder="Enter your email address.."
                      type="email"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <label>Phone Number</label>
                    <Field
                      name="phone"
                      // placeholder="Enter your Phone Number.."
                      type="text"
                    />
                  </div>
                  <div className="field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <label>Select Abstract File (e.g PDF/Word/PPT) *</label>
                    <input
                      name="abstractFile"
                      type="file"
                      accept=".pdf, .doc, .docx, .ppt, .pptx, .ppsx, .pptm"
                      onChange={(event) =>
                        handleFileChange(event, setFieldValue)
                      }
                      required
                    />
                    <ErrorMessage
                      name="abstractFile"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <label>Message </label>
                    <Field
                      as="textarea"
                      // placeholder="Write your message"
                      name="message"
                      type="text"
                    />
                    <ErrorMessage
                      name="message"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
                <button
                  className="ant-btn ant-btn-secondary w-100"
                  type="submit"
                >
                  Submit Abstract{" "}
                  <span className="ms-1 arrow-right">
                    <ArrowUpRight size={16} />
                  </span>
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
