const SponsorsData = [
  {
    title: "Gold",
    key: "01",
    benefits: [
      "Two 8 x 10 booths in prime location at the Annual Conference",
      "Four complimentary registrations to the Annual Conference from your company",
      "Two complimentary registrations to the Annual Conference from pharma/biotech manufacturers for your clients",
      "Invited to the special event to meet with industry leaders",
      "Have 10 min on podium to address SOS audience, or present with client at conference",
      "Participate on stage for one of the panels",
      "30 second advertisement to be played at the beginning of a General Session; based on availability",
      "Link and company logo on SOS website home page and the conference page for one year",
      "Sidebar ad on SOS website",
      "Enhanced verbal recognition as sponsor of the Annual Conference",
      "Enhanced sign recognition at Annual Conference",
      "Slide loop to be played before start of a General Session; based on availability",
      "Chair drop during a General Session at the conference; based on availability",
      "SOS will distribute white paper to all members (up to 3 white papers)",
    ],
  },
  {
    title: "Silver",
    key: "02",
    benefits: [
      "One 8 x 10 booth at the Annual Conference",
      "Three complimentary registrations to the Annual Conference",
      "One complimentary registration from pharma manufacturer to the Annual Conference to offer to your clients",
      "Seat on the panel discussion; based on availability",
      "20 second advertisement to be played at the beginning of a General Session; based on availability",
      "Link and company logo on SOS website home page and the conference page for one year",
      "Sidebar ad on SOS website",
      "Enhanced verbal recognition as sponsor of the Annual Conference",
      "Enhanced sign recognition at Annual Conference",
      "Slide loop to be played before start of a General Session; based on availability",
      "Chair drop during a General Session at the conference; based on availability",
      "SOS will distribute white paper to all members (up to 2 white papers)",
    ],
  },
  {
    title: "Exhibitor",
    key: "03",
    benefits: [
      "One 8 x 10 booth at the Annual Conference",
      "Two complimentary registrations to the Annual Conference",
      "Link and company logo on SOS website home page and the conference page for one year",
      "Verbal recognition as sponsor of the Annual Conference",
      "Sign recognition at Annual Conference",
    ],
  },
];

export default SponsorsData;
