import { Col, Row } from "antd";
import React from "react";
import "./Contact.scss";
import ContactForm from "./ContactForm";
import { Mail, ArrowUpRight } from "feather-icons-react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import AssetPath from "../../AssetPath/AssetPath";
import { useMediaQuery } from "react-responsive";

export default function Contact() {
  const isMediumScreen = useMediaQuery({ query: "(max-width: 768px)" });

  const contactWays = [
    // {
    //   title: "Address",
    //   icon: <MapPin />,
    //   desc: "Understock, Switzerland",
    //   linkText: "Visit Us",
    //   link: "/",
    //   class: "bg-white text-black border",
    // },
    {
      title: "Email us",
      icon: <Mail />,
      desc: "we’re here to help",
      linkText: "info@pharma-sos.com",
      link: "mailto:info@pharma-sos.com",
      class: "bg-white text-black border",
    },
    // {
    //   title: "Call Us",
    //   icon: <CallCalling />,
    //   desc: "Mon to Fri from 9:30 am - 6:30 pm",
    //   linkText: "+41-93736 476 734",
    //   link: "tel:+41-93736 476 734",
    //   class: "bg-white  text-black border",
    // },
  ];

  return (
    <React.Fragment>
      <PageTitle
        mainTitle="Contact us"
        breadcrumbItems={[{ name: "Contact us", path: "/contact-us" }]}
        image={AssetPath.breadcrumb.contactUsImage}
      />
      <section className="spacing d-flex  align-items-center justify-content-center contact-title-section bg-white spacing bg-yellow-purple-gradient position-relative">
        <div className="overflow-hidden w-100">
          <div className="container">
            <div
              className="highlight-title mt-4 text-center"
              data-aos="fade-up"
            >
              <h3 className="h1 text-primary">
                Have questions or need assistance? We’re here to help.
              </h3>
              <p className="lead text-black mt-3">
                Fill out the form and we’ll get back to you promptly.
              </p>
            </div>

            <div className="contact-ways">
              <Row
                gutter={[
                  { xs: 10, sm: 16, md: 24, lg: 32 },
                  { xs: 10, sm: 16, md: 24, lg: 20 },
                ]}
                className="pb-3 "
                align="middle"
                justify="center"
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 9 }}
                  lg={{ span: 9 }}
                >
                  {contactWays.map((data, i) => (
                    <div className="mb-3" key={i} data-aos="fade-up">
                      <a
                        href={data.link}
                        target="_blank"
                        rel="noreferrer"
                        data-aos="fade-up"
                      >
                        <div
                          className={`details position-relative ${data.class}`}
                        >
                          <ArrowUpRight className="position-absolute" />
                          <span className="icon">{data.icon}</span>
                          <h3 className="mb-0">{data.title}</h3>
                          <a
                            href={data.link}
                            target="_blank"
                            rel="noreferrer"
                            className="text-decoration-underline"
                          >
                            {data.linkText}
                          </a>
                        </div>
                      </a>
                    </div>
                  ))}
                  {!isMediumScreen && (
                    <img
                      src={AssetPath.images.aboutUs}
                      alt="Contact us"
                      className="w-100"
                      data-aos="fade-up"
                    />
                  )}
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <div
                    className="highlight-title border rounded card"
                    data-aos="fade-up"
                  >
                    <ContactForm />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="contact-support spacing bg1 position-relative">
        <div className="container">
          <Row
            gutter={[
              { xs: 10, sm: 16, md: 24, lg: 32 },
              { xs: 10, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
            align="middle"
            justify="center"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div className="highlight-title" data-aos="fade-up">
                <h3 className="text-center">
                  Just fill out the form and hit submit.
                </h3>
                <p className="text-center">we're here to assist</p>
              </div>
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section> */}
    </React.Fragment>
  );
}
