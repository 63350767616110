import { useRef } from "react";
import { Carousel } from "antd";
import TeamData from "../../Util/TeamData";

export default function ExpertTeam() {
  const carouselRef = useRef();

  //   const handleNext = () => {
  //     carouselRef.current.next();
  //   };

  //   const handlePrevious = () => {
  //     carouselRef.current.prev();
  //   };

  const carouselConfig = {
    draggable: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 1500,
    infinite: true,
    slidesToShow: 5,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section
      className="our-team spacing bg-white position-relative"
      data-aos="fade-up"
    >
      <div className="container">
        <div className="d-flex flex-column flex-md-row flex-lg-row align-items-center justify-content-between">
          <div className="text-center w-100">
            <h4 className="title mb-3 text-primary text-center">
              Meet the Board
            </h4>
          </div>
        </div>
        <div className="team-list">
          <Carousel {...carouselConfig} ref={carouselRef}>
            {TeamData.boardMembers.map((members, i) => (
              <div key={i} className="team-details position-relative">
                <div className="profile-image rounded overflow-hidden">
                  <img
                    src={members.profileImage}
                    alt={members.name}
                    className="w-100 rounded"
                  />
                </div>
                <div className="details text-center card">
                  <h4 className="text-ellipsis1 font-4 font-weight-bold mb-1">
                    {members.name}
                  </h4>
                  <p className="text-ellipsis2 mb-0">{members.position}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
}
