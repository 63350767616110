import { Col, Row } from "antd";
import { useRef } from "react";
import { Carousel } from "antd";
import { ChevronLeft, ChevronRight } from "feather-icons-react";
import SponsorsData from "./SponsorsData";
import "./Sponsors.scss";

export default function SponsorPackages({ className }) {
  const carouselRef = useRef();

  const handleNext = () => {
    carouselRef.current.next();
  };

  const handlePrevious = () => {
    carouselRef.current.prev();
  };

  const carouselConfig = {
    draggable: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    slidesToShow: 2,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section
      className={`sponsors-packages spacing-top bg-half-light-green ${className}`}
    >
      <div className="container">
        <Row
          gutter={[
            { xs: 0, sm: 16, md: 24, lg: 32 },
            { xs: 0, sm: 16, md: 24, lg: 20 },
          ]}
          className="pb-3 "
          align="center"
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 22 }}
            lg={{ span: 22 }}
            data-aos="fade-up"
          >
            <div className="sponsor-title d-flex align-items-center justify-content-between">
              <h2 className="title text-primary">Sponsorship Packages</h2>
              <div className="slide-navigations d-flex">
                <div
                  className="previous me-2 cursor-pointer"
                  onClick={handlePrevious}
                >
                  <ChevronLeft size={18} className="text-primary" />
                </div>
                <div className="next cursor-pointer" onClick={handleNext}>
                  <ChevronRight size={18} className="text-primary" />
                </div>
              </div>
            </div>

            <Carousel {...carouselConfig} ref={carouselRef}>
              {SponsorsData.map((sponsor, index) => (
                <div key={index} className="sponsor">
                  <div className="details card border rounded position-relative">
                    <div className="position-absolute key">{sponsor.key}</div>
                    <h4 className="text-primary">{sponsor.title} Package</h4>
                    <ul className="list-unstyled m-0 p-0">
                      {sponsor.benefits.map((benefit, i) => (
                        <li key={i}>{benefit}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>
      </div>
    </section>
  );
}
