import "./CTA.scss";
import AssetPath from "../../AssetPath/AssetPath";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";
import { useHistory } from "react-router-dom";

export default function CTA({
  noImage,
  title,
  subTitle,
  shortTitle,
  desc,
  label,
  image,
  onClick,
  className,
}) {
  const history = useHistory();

  return (
    <section
      className={`cta  position-relative overflow-hidden ${className}`}
      data-aos="fade-up"
    >
      {/* <img
        src={AssetPath.shape.brushStrokeGreenFlip}
        alt="Become a Volunteer"
        className="z-index position-absolute top-0 start-0 w-100"
      /> */}
      <div className="d-flex flex-column flex-md-row flex-lg-row align-items-center justify-content-center">
        {noImage ? (
          ""
        ) : (
          <div className="image position-relative">
            <img
              src={image || AssetPath.breadcrumb.joinWithUsImage}
              alt={title ? title : "Join with Pharma-SOS"}
            />
          </div>
        )}

        <div
          className={`content ${noImage ? "text-center" : ""}`}
          data-aos="fade-up"
        >
          {title ? (
            <span className="d-block sub-title text-uppercase font-2 text-primary">
              {title}
            </span>
          ) : (
            ""
          )}

          <h3 className="title text-primary">
            {subTitle || "Interested in joining the board?"}
          </h3>
          {shortTitle ? (
            <h4 className="text-primary fst-italic">{shortTitle}</h4>
          ) : (
            ""
          )}
          <p className="text-gray">
            {desc ||
              "Are you passionate about building a community of professionals? Are you looking for opportunities to be a community leader, with tons of networking opportunities, with your peers in the industry? Please reach out to us! Pharma-SOS would be excited to have you join us on this journey!”"}
          </p>
          <ButtonBox
            type="primary"
            label={label || "Join With Us"}
            onClick={onClick || (() => history.push("/contact-us"))}
            rightArrowIcon
          />
        </div>
      </div>
    </section>
  );
}
