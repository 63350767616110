import { Col, Row } from "antd";
import AssetPath from "../../AssetPath/AssetPath";
import PageTitle from "../../Components/PageTitle/PageTitle";
import TeamData from "../../Util/TeamData";
import AboutSection from "../LandingPage/AboutSection";
import CTA from "../LandingPage/CTA";

export default function AboutUs() {
  return (
    <>
      <PageTitle
        mainTitle="About Us"
        breadcrumbItems={[{ name: "About Us", path: "/about-us" }]}
        image={AssetPath.breadcrumb.aboutUsImage}
      />

      <AboutSection />

      <section className="our-team spacing bg-light-green position-relative">
        <div className="container">
          <div className="team-list">
            <Row
              gutter={[
                { xs: 0, sm: 16, md: 24, lg: 32 },
                { xs: 0, sm: 16, md: 24, lg: 20 },
              ]}
              align="center"
              justify="middle"
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 18 }}
                lg={{ span: 18 }}
              >
                <h3 className="text-center title mb-3 text-primary text-capitalize">
                  Board of Directors
                </h3>
                <Row
                  gutter={[
                    { xs: 0, sm: 15, md: 15, lg: 15 },
                    { xs: 0, sm: 5, md: 5, lg: 5 },
                  ]}
                  align="start"
                >
                  {TeamData.boardMembers.map((members, i) => (
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 6 }}
                      lg={{ span: 6 }}
                      data-aos="fade-up"
                      key={i}
                    >
                      <div className="team-details img-spacing position-relative">
                        <div className="profile-image rounded overflow-hidden">
                          <img
                            src={members.profileImage}
                            alt={members.name}
                            className="w-100 rounded"
                          />
                        </div>
                        <div className="details text-center card">
                          <h4 className="text-ellipsis1 font-4 font-weight-bold mb-1">
                            {members.name}
                          </h4>
                          <p className="text-ellipsis2 mb-0">
                            {members.position}
                          </p>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
                <h3 className="text-center title mb-3 text-primary text-capitalize">
                  Volunteers
                </h3>
                <Row
                  gutter={[
                    { xs: 0, sm: 15, md: 15, lg: 15 },
                    { xs: 0, sm: 5, md: 5, lg: 5 },
                  ]}
                  align="start"
                >
                  {TeamData.volunteers.map((members, i) => (
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 6 }}
                      lg={{ span: 6 }}
                      data-aos="fade-up"
                      key={i}
                    >
                      <div className="team-details img-spacing position-relative">
                        <div className="profile-image rounded overflow-hidden">
                          <img
                            src={members.profileImage}
                            alt={members.name}
                            className="w-100 rounded"
                          />
                        </div>
                        <div className="details text-center card">
                          <h4 className="text-ellipsis1 font-4 font-weight-bold mb-1">
                            {members.name}
                          </h4>
                          <p className="text-ellipsis2 mb-0">
                            {members.position}
                          </p>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <CTA
        title=""
        noImage
        subTitle="Join Our Board"
        label="Apply to Join"
        desc={
          <>
            <p>
              Are you passionate about building a professional community and
              expanding your network in the industry?
            </p>
            <p>
              We invite you to join the Pharma-SOS board and collaborate with
              leaders in the field. Reach out to learn more!
            </p>
          </>
        }
      />
    </>
  );
}
