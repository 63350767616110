import { Col, Row } from "antd";
import React from "react";
import "../Contact/Contact.scss";
import PageTitle from "../../Components/PageTitle/PageTitle";
import AssetPath from "../../AssetPath/AssetPath";
import SubmitAbstractForm from "../LandingPage/SubmitAbstractForm";

export default function SubmitAbstract() {
  return (
    <React.Fragment>
      <PageTitle
        mainTitle="Abstract Submission"
        breadcrumbItems={[
          { name: "Abstract Submission", path: "/abstract-submission" },
        ]}
        image={AssetPath.breadcrumb.abstractSubmission}
      />
      <section className="spacing d-flex  align-items-center justify-content-center contact-title-section bg-white spacing bg-yellow-purple-gradient position-relative">
        <div className="overflow-hidden w-100">
          <div className="container">
            <div
              className="highlight-title mt-4 text-center"
              data-aos="fade-up"
            >
              <h3 className="text-primary">Submit Your Abstract</h3>

              <p className="text-gray font-4">
                Present your cutting-edge insights at{" "}
                <span className="text-primary">Innovate Pharma 2024</span>.
                Engage with industry leaders,{" "}
                <span className="d-block">
                  contribute to pivotal discussions, and collaborate with global
                  experts!
                </span>
              </p>
            </div>

            <div className="contact-ways">
              <Row
                gutter={[
                  { xs: 10, sm: 16, md: 24, lg: 32 },
                  { xs: 10, sm: 16, md: 24, lg: 20 },
                ]}
                className="pb-3 "
                align="middle"
                justify="center"
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 20 }}
                  lg={{ span: 20 }}
                >
                  <div
                    className="highlight-title border rounded card"
                    data-aos="fade-up"
                  >
                    <SubmitAbstractForm />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
