import { Col, Row } from "antd";
import AssetPath from "../../AssetPath/AssetPath";
import PageTitle from "../../Components/PageTitle/PageTitle";
import GalleryData from "./GalleryData";
import { useHistory } from "react-router-dom";

const Gallery = () => {
  const navigate = useHistory();

  return (
    <>
      <PageTitle
        mainTitle="Gallery"
        breadcrumbItems={[{ name: "Gallery", path: "/gallery" }]}
        image={AssetPath.breadcrumb.aboutUsImage}
      />

      <section className="spacing">
        <div className="container">
          {GalleryData[0]?.innovatePharma && (
            <Row
              gutter={[
                { xs: 0, sm: 16, md: 24, lg: 32 },
                { xs: 0, sm: 16, md: 24, lg: 20 },
              ]}
              align="left"
              justify="middle"
            >
              {GalleryData.map((data, i) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                  key={i}
                  onClick={() => navigate.push(data.innovatePharma.path)}
                  className="cursor-pointer"
                >
                  <div className="details card border">
                    <img
                      src={data.innovatePharma.image}
                      alt={data.innovatePharma.Title}
                      title={data.innovatePharma.Title}
                      className="w-100 mb-2 rounded"
                    />
                    <h3 className="h4 text-primary mb-0">
                      {data.innovatePharma.Title}
                    </h3>
                    <p className="text-secondary font-4 mb-0">
                      {data.innovatePharma.subTitle}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </div>
      </section>
    </>
  );
};

export default Gallery;
