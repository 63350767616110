import React from "react";
import { Row, Col, Collapse } from "antd";
import "./FAQ.scss";
import { ChevronDown, ChevronUp } from "feather-icons-react";

const { Panel } = Collapse;
function callback(key) {
  console.log(key);
}

const collapseItem = [
  {
    key: "01",
    title: "What benefits do Gold Sponsors receive?",
    desc: "Gold Sponsors enjoy prime booth placement, complimentary registrations, exclusive opportunities to meet with industry leaders, podium addresses, panel participation, advertisements, prominent website and event signage, and white paper distribution.",
  },
  {
    key: "02",
    title: "What are the advantages of being a Silver Sponsor?",
    desc: "Silver Sponsors receive booth space, complimentary registrations, panel participation, advertisements, website and event signage, and the ability to distribute white papers to all members.",
  },
  {
    key: "03",
    title: "What do Exhibitor Sponsors get?",
    desc: "Exhibitor Sponsors benefit from booth space, complimentary registrations, website features, and verbal and sign recognition at the Annual Conference.",
  },
  {
    key: "04",
    title:
      "How long will my company’s logo and link be featured on the SOS website?",
    desc: "All sponsorship levels include a link and company logo featured on the SOS website home page and conference page for one year.",
  },
  {
    key: "05",
    title:
      "Can sponsors distribute their own white papers to conference attendees?",
    desc: "Gold Sponsors can distribute up to three white papers, while Silver Sponsors can distribute up to two white papers to all members.",
  },
  {
    key: "06",
    title:
      "Are there any opportunities for sponsors to address the audience during the conference?",
    desc: "Gold Sponsors can have 10 minutes on the podium or present with a client, and participate in panel discussions. Silver Sponsors may also have a seat on a panel discussion based on availability.",
  },
];

export default function FAQList() {
  return (
    <section className="faq spacing bg-light-green">
      <div className="container">
        <div className="text-center">
          <h4 className="title mb-4 text-primary">
            Frequently Asked Questions
          </h4>
        </div>
        <Row
          gutter={[
            { xs: 0, sm: 16, md: 24, lg: 32 },
            { xs: 0, sm: 16, md: 24, lg: 20 },
          ]}
          className="pb-3 "
          align="center"
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 18 }}
            lg={{ span: 18 }}
          >
            <div class="highlight-title " data-aos="fade-up">
              <Collapse
                onChange={callback}
                expandIconPosition="right"
                expandIcon={({ isActive }) =>
                  isActive ? <ChevronUp size={20} /> : <ChevronDown size={20} />
                }
              >
                {collapseItem.map((list, i) => (
                  <Panel
                    header={
                      <span>
                        <span className="text-primary h3">{list.key}</span>
                        <span className="ms-3 font-weight-bold">
                          {list.title}
                        </span>
                      </span>
                    }
                    key={list.i}
                    className="custom-panel"
                  >
                    <p className="font-4 text-gray">{list.desc}</p>
                  </Panel>
                ))}
              </Collapse>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}
