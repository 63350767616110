import React, { useState } from "react";
import CTA from "../LandingPage/CTA";
import PageTitle from "../../Components/PageTitle/PageTitle";
import AssetPath from "../../AssetPath/AssetPath";
import AgendaList from "./AgendaList";
import ModalBox from "../../Components/ModalBox/ModalBox";
import SubmitAbstractForm from "../LandingPage/SubmitAbstractForm";
import Speakers from "./Speakers";
import { Link, useHistory } from "react-router-dom";
import SponsorPackages from "../Sponsors/SponsorPackages";
import InnovatePharmaEventDetails from "./InnovatePharmaEventDetails";

export default function Agenda() {
  const [open, setOpen] = useState();
  const history = useHistory();

  return (
    <>
      <PageTitle
        mainTitle="Agenda"
        breadcrumbItems={[{ name: "Agenda", path: "/agenda" }]}
        image={AssetPath.breadcrumb.agendaImage}
        strokeType="green"
      />
      <AgendaList />

      <section
        className="presentations our-team spacing position-relative bg-light-green"
        data-aos="fade-up"
      >
        <div className="container">
          <h3 className="text-primary text-center">
            Innovate Pharma 2024 Presentation
          </h3>
          <ul className="list-unstyled">
            {InnovatePharmaEventDetails.map((data, i) => (
              <li>
                <a
                  href={data.path}
                  target="_blank"
                  className="d-flex align-items-center   border rounded mb-2"
                >
                  <div className="count d-flex align-items-center justify-content-center font-4 me-3 bg-primary text-white">
                    {i + 1}
                  </div>
                  <div className="text-left">
                    <h5 className="d-block w-100 mb-0">{data.title}</h5>
                    <div className="d-block">
                      <p className="mb-0">
                        <strong>{data.speaker}</strong>• <em>{data.role}</em>{" "}
                        {data.company ? " •" : ""}{" "}
                        <strong>{data.company} </strong>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <Speakers />

      <CTA
        className="bg-light-green"
        subTitle="Submit Your Abstract"
        desc="Present your cutting-edge insights at Innovate Pharma 2024. Engage with industry leaders, contribute to pivotal discussions, and collaborate with global experts!"
        label="Submit"
        noImage
        onClick={() => history.push("/abstract-submission")}
      />

      <ModalBox
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={null}
        width={900}
      >
        <SubmitAbstractForm />
      </ModalBox>
    </>
  );
}
