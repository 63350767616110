import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../Components/PageTitle/PageTitle";
import AssetPath from "../../AssetPath/AssetPath";
import { ArrowUpRight } from "feather-icons-react";

export default function PageNotFound() {
  return (
    <React.Fragment>
      <PageTitle
        mainTitle="Page Not Found"
        breadcrumbItems={[{ name: "Error Page", path: "/error" }]}
        image={AssetPath.breadcrumb.agendaImage}
      />
      <section className="faq spacing light-background">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
            align="center"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
              data-aos="fade-up"
            >
              <div class="highlight-title text-center" data-aos="fade-up">
                <h1>404 Error - Page Not Found</h1>
                <p>
                  The page you are looking for could not be found. Please check
                  the URL or go back to the homepage.
                </p>
                <Link to="/" className="ant-btn ant-btn-primary">
                  Go back to Home
                  <span className="ms-1 arrow-right">
                    <ArrowUpRight size={16} />
                  </span>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
}
